<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header column">
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label>{{ $t("workspace.pnt.station") }}</label>
            <select name="" id="">
              <option value="" selected>{{ $t("workspace.pnt.select") }} {{ $t("workspace.pnt.station") }}</option>  
            </select> 
          </div>
          <div class="settings-input">
            <label>{{ $t("workspace.pnt.date") }}</label>
            <input type="date" v-model="filterModel.name">
          </div>
          <button>{{ $t("workspace.pnt.report") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'

export default {
  setup() {
    const filterModel = ref({
      code: '',
      name: ''
    })

    return {
      filterModel
    }
  }
}
</script>
<style lang="">
  
</style>